import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from '..';
import { getAdminCustomerId, getAdminUserPaymentSources, getCustomerIdByUserId, getPaymentDestination, getUserPaymentSourcesByUserId, savePaymentIntent } from '../../services/api';
import config from '../../constants/config';

const LeanPaymentGateway = ({ amount, currency, invoiceId, tenantDetails, navigateFrom, propertyShortCode }) => {
  const [leanSdkLoaded, setLeanSdkLoaded] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [customerToken, setCustomerToken] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [apiAccessToken, setApiAccessToken] = useState('');
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    if (navigateFrom === "CompanyPaymentPage") {
        if(tenantDetails){
            getCustomerIdByUserId({userId: tenantDetails?.id})
            .then((response)=>{
                setCustomerId(response.data?.customerId);
                setCustomerToken(response.data?.customerAccessToken);
                setApiAccessToken(response.data?.apiAccessToken);
            })
            .catch((error)=>console.log("erorrrrr", error));
        }
    } else {
        
        getAdminCustomerId()
        .then((response)=>{
            setCustomerId(response.data?.customerId);
            setCustomerToken(response.data?.customerAccessToken);
            setApiAccessToken(response.data?.apiAccessToken);
        })
        .catch((error)=>console.log("erorrrrr", error));
    }
},[]);


  // Load Lean SDK script dynamically
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js';
    script.async = true;

    script.onload = () => {
      console.log('Lean SDK Loaded');
      setLeanSdkLoaded(true);  // SDK is now loaded and available
    };

    script.onerror = () => {
      console.error('Failed to load Lean SDK.');
    };

    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  function myCallback(responseObject) {
    console.log("callback", responseObject)
    if(!readyForPayment && responseObject.status === "SUCCESS"){
      setReadyForPayment(true);
      setIsLoading(true);
    }
    else{
        setReadyForPayment(false);
        setIsLoading(false);
        
    }
    // insert actions you want to perform on SDK close
  }
  function myCallback1(responseObject) {
    if(!readyForPayment && responseObject.status === "SUCCESS"){
      setReadyForPayment(true);
      setIsLoading(true);
    }
    else{
        setReadyForPayment(false);
        setIsLoading(false);
        const redirectUrl = `/${propertyShortCode}`;
        setTimeout(() => {
          window.location.href = redirectUrl;
      }, 3000);
    }
  }

  const getPaymentSource = (customerId, paymentSourceId, apiAccessToken) =>{
    console.log("pyyyy====", `${config.LEANTECH_BASE_URL}/customers/v1/${customerId}/payment-sources/${paymentSourceId}` )
    return axios.get(
        `${config.LEANTECH_BASE_URL}/customers/v1/${customerId}/payment-sources/${paymentSourceId}`, 
        {
            headers: {
              Authorization: `Bearer ${apiAccessToken}`,
              'Content-Type': 'application/json',
            },
          }
    )
  }

  const checkCreateBeneficiery = (Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback) =>{
    const isSandbox = process.env.LEANTECH_BASE_URL && process.env.LEANTECH_BASE_URL.includes('sandbox') ? 'true' : 'false';

    if (paymentSources.length > 0) {
        const paymentSourceId = paymentSources[0]?.paymentSourceId;
        getPaymentSource(customerId, paymentSourceId, apiAccessToken)
        .then((paymentSourceResponse)=>{
            console.log("paye=====", paymentSourceResponse)
            const paymentDestinationIds = paymentSourceResponse.data.beneficiaries
              .map((beneficiary) => beneficiary.payment_destination_id)
            if (!paymentDestinationIds.includes(destinationId)) {
                Lean.createBeneficiary({
                    app_token: `${config.LEANTECH_APP_ID}`,
                    customer_id: customerId,
                    payment_source_id: paymentSourceId,
                    payment_destination_id: destinationId,
                    access_token: customerToken,
                    sandbox: isSandbox,
                    callback: myCallback,
                  });
            }else{
                setReadyForPayment(true);
            }
        })
        .catch()
    } else {
        Lean.connect({
            access_token: customerToken,
          customer_id: customerId,
          permissions: ["payments", "identity","accounts","transactions","balance"],
          app_token: `${config.LEANTECH_APP_ID}`,
          payment_destination_id: destinationId,
          sandbox: isSandbox,
          callback: myCallback,
        });
    }
  }

  useEffect(()=>{
    setIsLoading(true);
getPaymentDestination(invoiceId)
.then((response)=>{setDestinationId(response.data.paymentDestination.paymentDestinationId)})
.catch((error)=>{
    console.log("Error getting payment destination Id", error)
})
  },[])

  useEffect(() => {
    if (leanSdkLoaded) {
      const Lean = window.Lean;  // Lean SDK is loaded
      if (Lean) {
        if(customerId && customerToken && destinationId && apiAccessToken){
            if (navigateFrom === "CompanyPaymentPage") {
                if(tenantDetails){
                    getUserPaymentSourcesByUserId({userId: tenantDetails?.id})
                    .then((response)=>{
                        const paymentSources = response.data;
                        checkCreateBeneficiery(Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback);
                    })
                    .catch((error)=>{
                        console.log("Error getting payment Sources", error)
                    })
                }
            }else{
                getAdminUserPaymentSources()
                .then((response)=>{
                    const paymentSources = response.data;
                    checkCreateBeneficiery(Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback);
                })
                .catch((error)=>{
                    console.log("Error getting payment Sources", error)
                })
            }
    }
      } else {
        console.error('Lean SDK is not available.');
      }
    }
  }, [leanSdkLoaded, customerId, customerToken, destinationId, apiAccessToken]);

  useEffect(() => {
    console.log("apiaccesstoken", apiAccessToken);
    console.log(readyForPayment, customerToken, currency, amount, destinationId, customerId)
    if (readyForPayment && customerToken && currency && amount && destinationId && customerId) {
      axios
        .post(
          `${config.LEANTECH_BASE_URL}/payments/v1/intents`,
          {
            amount,
            currency,
            payment_destination_id: destinationId,
            customer_id: customerId,
            description: 'YOUR_TRANSACTION_REFERENCE',
          },
          {
            headers: {
              Authorization: `Bearer ${apiAccessToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const paymentIntentId = response.data.payment_intent_id;
          savePaymentIntent(invoiceId, paymentIntentId).then((response)=>{}).catch((error)=>{console.log("error====", error.message)})
          const Lean = window.Lean; // Ensure Lean SDK is loaded
          const isSandbox = process.env.LEANTECH_BASE_URL && process.env.LEANTECH_BASE_URL.includes('sandbox') ? 'true' : 'false';

          if (Lean) {
            Lean.pay({
                // app_token: `${config.LEANTECH_APP_ID}`,
                access_token: customerToken,
                payment_intent_id: paymentIntentId,
                show_balances: false,
                // account_id: "CHOSEN_ACCOUNT_ID",
                sandbox: isSandbox,
                callback: myCallback1,
                });
          } else {
            console.error('Lean SDK is not loaded.');
          }
        })
        .catch((error) => console.error('Payment initiation error:', error.message));
    }
  }, [readyForPayment, customerToken, amount, currency, destinationId, customerId]);

  return (
    <div>
        {isLoading && <Loader />}
        <script src="https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js"></script>
        <div id="lean-link"></div>
      <h3>Lean Payment Gateway</h3>
      <p>Amount: {amount}</p>
      <p>Currency: {currency}</p>
      <div id="lean-link"></div>
    </div>
  );
};

export default LeanPaymentGateway;
